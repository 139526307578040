const lowerRegex = /(?=.*[a-z])/
const upperRegex = /(?=.*[A-Z])/
const numericRegex = /(?=.*[0-9])/
const specialRegex = /(?=.*[!@#$%^&*"')(+\-,./:;<=>?@[\]_`}{|~\\])/
const whitespaceRegex = /\s/

export const validatePassword = (password: string, minPasswordLength: number, forceResolve?: boolean) => {
  if (forceResolve) {
    return Promise.resolve()
  }

  if (!password) {
    return Promise.reject('auth.signup.field.password.validation.required')
  }
  if (whitespaceRegex.test(password)) {
    return Promise.reject('auth.signup.field.password.validation.whitespace')
  }
  if (!lowerRegex.test(password)) {
    return Promise.reject('auth.signup.field.password.validation.lower')
  }
  if (!upperRegex.test(password)) {
    return Promise.reject('auth.signup.field.password.validation.upper')
  }
  if (!numericRegex.test(password)) {
    return Promise.reject('auth.signup.field.password.validation.numeric')
  }
  if (!specialRegex.test(password)) {
    return Promise.reject('auth.signup.field.password.validation.special')
  }
  if (password.length < minPasswordLength) {
    return Promise.reject('auth.signup.field.password.validation.length')
  }

  return Promise.resolve()
}

export enum PasswordRejectionError {
  TooCommon = 'TooCommon',
  TooSimilar = 'TooSimilar',
  TheSameAsCurrent = 'TheSameAsCurrent'
}
